/* eslint-disable prettier/prettier */
export default {
  loading: 'லோடிங்...',
  'no-update': 'ஏற்கெனவே சமீபத்திய பதிப்பு',
  home: {
    tab: {
      home: 'ஹோம்',
      result: 'ரிசல்ட்',
      casino: 'கேசினோ',
      scratch: 'ஸ்கிராச்',
      referral: 'ரெஃபெறல்',
      me: 'மீ',
      live: 'லைவ்',
      liveCasino: 'நேரடி கேசினோ',
      lottery: 'லாட்டரி',
      promotion: 'முன்னேற்றம்',
      proxyhome: 'ஏஜென்சி',
    },
    scratch: {
      free: 'ஃப்ரீ',
    },
    kerala: {
      title: 'கேரளா ஸ்டேட் லாட்டரி ஹோம்',
      no: 'நெம்பர்.',
    },
    digit: {
      title: '3 டிஜிட் கேம்',
      booking: 'டைம் போர் நெஸ்ட் புக்கிங்',
      ticket: 'டிக்கெட்',
    },
    dice: {
      title: 'டைஸ் கேம்',
      play: 'ப்பிளே நொவ்',
    },
    color: {
      title: 'கலர் ப்ரெடிக்ஷன்',
    },
    matka: {
      bid: 'பெட்',
      win: 'வின்',
      open: 'ஓபன்',
      close: 'கிளோஸ்',
      title: 'ஷட்ட மட்க ',
    },
    'live-casino': {
      title: 'லைவ் கேசினோ',
    },
    tip: {
      closed: '{{name}} ஈஸ் கிளோஸ்ட் நொவ் , ப்ளீஸ் ச்சூஸ் நெஸ்ட் கேம் ',
      copied: 'காப்பி சக்ஸஸ்புள்ளி, ப்ளீஸ் ஷேர் ஹர் வித்பிரென்ஸ்',
      downTips1: 'Suplottery ஆப் பெறுக',
      downTips2: 'சேவைகளை தொடங்குங்கள்',
    },
    label: {
      menu: 'மெனு ',
      viewAll: 'வியூ ஆல் பைநௌ',
      buyNow: 'Buy Now',
      'lucky-spin': 'லக்கி ஸ்பின்',
      rebate: 'ரிபெட்',
      'free-lottery': 'ப்பிரி லாட்டரி',
      referral: 'ரெபரரால்',
      referFriend: 'நண்பர் பரிந்துரை',
      agent: 'ஏஜென்ட்',
      promotion: 'பதவி உயர்வு',
    },
    luckyspin: {
      add: 'அட்',
      spin: 'ஸ்பின்',
      won: 'வின்',
      winner: 'பிக் வின்னர்ஸ்',
      mySpin: 'மை ஸ்பின்',
    },
  },
  me: {
    webview: {
      vip: 'மெம்பெர் சென்டர்',
    },
    new: 'நியூ',
    user: {
      userId: 'யூசர் ஐடி',
      moreExciting: 'மோர் எஸ்ஸிட்டிங் ஆப்டர் லொக்கிங் இன்',
      login: 'உள்நுழைய',
      loginUpper: 'மேல் உள்நுழைவு',
      register: 'பதிவு',
    },
    money: {
      totalWallet: 'டோட்டல் வாலட்',
      cashBalance: 'கேஷ் பேலன்ஸ்',
      withdrawAmount: 'வித்ட்ராஅமௌன்ட்',
    },
    vip: {
      recharge: 'ரீசார்ஜ்',
      move: 'மோர் டு ரீச் லெவெல் ',
      deposit: 'வைப்பு',
    },
    bottom: {
      recharge: 'ரீசார்ஜ்',
      withdraw: 'வித்ட்ரா',
      transfer: 'பரிமாற்றம்',
      proxy: 'ஏஜென்சி சென்டர்',
      logout: 'வெளியேறு',
      resultHistory: 'ரிசல்ட் ஹிஸ்டரி',
      myBets: 'மை பெட்',
      myTransactions: 'எனது பரிவர்த்தனைகள்',
      commission: 'மை கமிஷன்',
      notify: 'நோட்டிபிகேஷன்',
      lang: 'மொழி',
      customer: 'கஸ்டமர் சர்வீஸ்',
      update: 'அப்டேட்',
      password: 'பாஸ்வர்டு',
    },
    language: {
      switch: 'மொழி மாற்றம்',
    },
    tip: {
      cashTitle: 'கேஷ் பேலன்ஸ்e:',
      cashContent:
        '"பண இருப்பு" என்பது பந்தயம் அல்லது பிற பரிவர்த்தனைகளுக்குப் பயன்படுத்தப்படும் உங்கள் கணக்கில் உள்ள உண்மையான பண நிதியைக் குறிக்கிறது.',
      withdrawTitle: 'வித்ட்ராவ் அமௌன்ட்:',
      withdrawContent:
        '"திரும்பப் பெறக்கூடிய தொகை" என்பது உங்கள் கணக்கில் இருந்து திரும்பப் பெறக்கூடிய உங்கள் பண இருப்பின் ஒரு பகுதியாகும். இது பணத் தேவைகளைப் பூர்த்தி செய்த பணத்தைக் குறிக்கிறது, இது நிதியைத் திரும்பப் பெறுவதற்கு முன்பு பூர்த்தி செய்யப்பட வேண்டும்..',
    },
  },
  invitation: {
    title: {
      singUp: 'ப்பிரி லாட்டரி டிக்கெட்ஸ்',
    },
    label: {
      claim: 'உங்கள் டிக்கெட்டைப் பெறுங்கள்',
    },
  },
};
