export default {
  loading: 'लोडिंग...',
  'no-update': 'नया वर्ज़न पहले से अपडेट है',
  home: {
    tab: {
      home: 'होम',
      result: 'रिजल्ट',
      casino: 'कैसीनो',
      scratch: 'स्क्रैच',
      referral: 'रेफरल',
      me: 'मुझे',
      live: 'लाइव',
      liveCasino: 'लाइव कैसीनो',
      lottery: 'लॉटरी',
      promotion: 'प्रचार',
      proxyhome: 'एजेंसी',
    },
    scratch: {
      free: 'फ्री',
    },
    kerala: {
      title: 'केरल राज्य लॉटरी',
      no: 'NO.',
    },
    digit: {
      title: '3 डिजिट गेम',
      booking: 'अगली बुकिंग का समय',
      ticket: 'टिकट',
    },
    dice: {
      title: 'डाइस गेम',
      play: 'अभी खेलें',
    },
    color: {
      title: 'कलर प्रिडिक्शन',
    },
    matka: {
      bid: 'बिड',
      win: 'जीत',
      open: 'ओपन',
      close: 'क्लोज',
      title: 'सट्टा मटका',
    },
    'live-casino': {
      title: 'लाइव कैसीनो',
    },
    tip: {
      closed: '{{name}} अभी बंद है, कृपया अगला खेल चुनें',
      copied: 'सफलतापूर्वक कॉपी किया गया, कृपया दोस्तों के साथ शेयर करें',
      downTips1: 'Suplottery ऐप पाएं',
      downTips2: 'सेवाएं शुरू करें',
    },
    label: {
      menu: 'मेन्यू',
      viewAll: 'सभी देखें',
      buyNow: 'अभी खरीदें',
      'lucky-spin': 'लकी स्पिन',
      rebate: 'रिबेट',
      'free-lottery': 'फ्री लॉटरी',
      referral: 'रेफरल',
      referFriend: 'मित्र संदर्भ',
      agent: 'एजेंट',
      promotion: 'प्रतिनिधि',
    },
    luckyspin: {
      add: 'जोड़ें',
      spin: 'स्पिन',
      won: 'जीत',
      winner: 'बड़े विजेता',
      mySpin: 'मेरी स्पिन',
    },
  },
  me: {
    webview: {
      vip: 'मेंबर सेंटर',
    },
    new: 'नया',
    user: {
      userId: 'यूजर आईडी',
      moreExciting: 'लॉगिन करने के बाद और रोमांचक',
      login: 'लॉग इन करें',
      loginUpper: 'लॉगिन',
      register: 'पंजीकरण',
    },
    money: {
      totalWallet: 'टोटल वॉलेट',
      cashBalance: 'कैश बैलेंस',
      withdrawAmount: 'विड्रॉल योग्य अमाउंट',
    },
    vip: {
      recharge: 'रिचार्ज',
      move: 'लेवल बढ़ाने के लिए',
      deposit: 'जमा',
    },
    bottom: {
      recharge: 'रिचार्ज',
      withdraw: 'विड्रॉल',
      transfer: 'ट्रांसफर',
      proxy: 'प्रॉक्सी सेंटर',
      logout: 'लॉग आउट',
      resultHistory: 'रिजल्ट हिस्ट्री',
      myBets: 'माय बेट्स',
      myTransactions: 'मेरा लेन-देन',
      commission: 'मेरा कमीशन',
      notify: 'सूचनाएँ',
      lang: 'भाषाएँ',
      customer: 'कस्टमर सर्विस',
      update: 'अपडेट',
      password: 'पासवर्ड',
    },
    language: {
      switch: 'भाषा बदलें',
    },
    tip: {
      cashTitle: 'कैश बैलेंस:',
      cashContent:
        '"कैश बैलेंस " आपके खाते में उपलब्ध वास्तविक कैश बैलेंस को दर्शाता है जिसे बेट लगाने या अन्य लेन-देन के लिए उपयोग किया जा सकता है.',
      withdrawTitle: 'विड्रॉल अमाउंट:',
      withdrawContent:
        '"विड्रॉल अमाउंट"आपके खाते से विड्रॉल की जा सकने वाला अमाउंट है। यह वह अमाउंट है जो आपके खाते से विड्रॉल की सभी शर्तो को पूरा करता है,जो कि अमाउंट को विड्रॉल करने से पहले आवश्यकता होती है.',
    },
  },
  invitation: {
    title: {
      singUp: 'फ्री लॉटरी टिकट',
    },
    label: {
      claim: 'अपना टिकट क्लेम करें',
    },
  },
  wallet: {
    title: 'मेरा वॉलेट',
    total: 'टोटल वॉलेट',
    recharge: 'रिचार्ज',
    main: 'मेन वॉलेट',
    third: 'थर्ड पार्टी वॉलेट',
    transfer: 'मेन वॉलेट ट्रांसफर',
    translation: 'अनुवाद：{{second}}सेकंड',
    transComplete: 'परिवर्तन पूर्ण हुआ',
    transferNav: {
      recharge: 'रिचार्ज',
      withdraw: 'विड्रॉल',
      transfer: 'ट्रांसफर',
    },
  },
};
